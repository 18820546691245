import { graphql, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { SectionContainer, SectionContent } from '../components/section';
import { BlogTemplateQuery } from '../generated/graphqlTypes';
import { formatDate } from '../helpers/date';
import { fixFluid } from '../helpers/image';
import typographyPlugin from '../styles/typographyPlugin';
export type Props = Pick<PageProps<BlogTemplateQuery>, 'data'>;

const Blog: React.FC<Props> = ({ data }) => {
  const page = data?.datoCmsBlog;
  if (!page) throw new Error('Invalid blog slug');

  const fluid = fixFluid(page?.coverImage?.fluid);

  return (
    <Page>
      <HelmetDatoCms seo={page?.seoMetaTags} />
      <PageTitle
        link={{ text: '< Back to Blog', url: '/' }}
        subtitle={`Published on ${formatDate(page.publishedDate)}`}
      >
        {page.title}
      </PageTitle>

      <SectionContainer>
        <SectionContent isSmallMaxWidth={true}>
          {fluid && (
            <Img
              fluid={fluid}
              style={{
                marginBottom: `${typographyPlugin.options.baseLineHeight}rem`,
              }}
            />
          )}

          <div
            dangerouslySetInnerHTML={{
              __html: page.descriptionNode?.childMarkdownRemark?.html ?? '',
            }}
          />
        </SectionContent>
      </SectionContainer>
    </Page>
  );
};

export default Blog;

export const query = graphql`
  query BlogTemplate($slug: String!) {
    datoCmsBlog(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      publishedDate
      coverImage {
        url
        fluid(maxWidth: 910, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
